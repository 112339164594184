import { useGSAP } from '@gsap/react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useRef } from 'react'

import { cn } from '~/utils/cn'

import Carousel1 from './assets/waitlist/1.png'
import Carousel2 from './assets/waitlist/2.jpeg'
import Carousel3 from './assets/waitlist/3.png'
import Carousel4 from './assets/waitlist/4.png'
import Carousel5 from './assets/waitlist/5.png'
import Carousel6 from './assets/waitlist/6.png'

gsap.registerPlugin(ScrollTrigger)

const imageSrcs = [
  Carousel1,
  Carousel2,
  Carousel3,
  Carousel4,
  Carousel5,
  Carousel6,
]

const IMAGE_SIZES = [
  [158, 207],
  [225, 280],
  [246, 190],
  [191, 195],
  [209, 261],
  [179, 153],
] as const

export const ImageOrbit = ({ className }: { className?: string }) => {
  const host = useRef<HTMLDivElement | null>(null)

  // GSAP
  useGSAP(
    () => {
      const tls = [...Array(6)].map(() => gsap.timeline({ repeat: -1 }))
      tls.forEach((tl, i) => {
        tl.fromTo(
          `#arm${i}`,
          {
            rotation: 0 + i * 60,
          },
          {
            rotation: 360 + i * 60,
            duration: 60,
            ease: 'none',
            scrollTrigger: {
              trigger: host.current,
              start: 'top+=100px bottom',
              end: `center bottom`,
              scrub: false,
            },
          },
          0
        ).fromTo(
          `#box${i}`,
          {
            rotation: 0 - i * 60,
          },
          {
            rotation: -360 - i * 60,
            duration: 60,
            ease: 'none',
            scrollTrigger: {
              trigger: host.current,
              start: 'top+=100px bottom',
              end: `center bottom`,
              scrub: false,
            },
          },
          0
        )
      })
    },
    { scope: host }
  )

  return (
    <div ref={host} className={cn(className)}>
      {[...Array(6)].map((_, i) => (
        <div
          key={`arm${i}`}
          id={`arm${i}`}
          className="absolute left-1/2 top-1/2 z-10 h-px w-[150px] origin-left scale-75 lg:scale-100"
        >
          <div
            id={`box${i}`}
            style={{
              width: IMAGE_SIZES[i]?.[0],
              height: IMAGE_SIZES[i]?.[1],
              left: 250,
              backgroundImage: `url(${imageSrcs[i]})`,
            }}
            className="img relative -top-[50px] h-[100px] w-[100px] rounded-sm bg-positive bg-cover bg-no-repeat opacity-50"
          />
        </div>
      ))}
    </div>
  )
}
